import React from "react";
import "./video.less"
import Layout from "../components/layout"

    
const VideoPage = () => (
    <Layout>
    <div class="container">
        <video poster="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.jpg" controls crossorigin>
            <source src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.mp4" type="video/mp4"/>
            <source src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.webm" type="video/webm"/>

            <track kind="captions" label="English" srclang="en" src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.en.vtt" default/>

            <a href="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.mp4" download>Download</a>
        </video>
        
        <div class="actions">
            <button type="button" class="btn js-play">Play</button>
            <button type="button" class="btn js-pause">Pause</button>
            <button type="button" class="btn js-stop">Stop</button>
            <button type="button" class="btn js-rewind">Rewind</button>
            <button type="button" class="btn js-forward">Forward</button>
        </div>
    </div>
    </Layout>
);

export default VideoPage;